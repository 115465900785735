"use strict";

var ajax = require('../../ajax'),
    util = require('../../util');

var installmentLocales = ['pt_BR', 'ja_JP', 'tr_TR', 'es_MX'];
var MASKED_CC_PREFIX = '************';
var selectedMethod;
var componentsObj = {};
var checkoutConfiguration;
var sessionsResponse;
var paymentMethodsResponse;
var checkout;
var isValid;
var orderNumber;
var selectedBrand;

function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}

function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true; _e = err;
    } finally {
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally {
            if (_d) throw _e;
        }
    } return _arr;
}

function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}

/**
 * @function
 * @description Validate Billing Form
 */
function validateBillingForm() {
    var isValidForm = true;
    var $form = $('#dwfrm_billing');

    // Validate Customer Tax ID field if it exists on form
    if ($("#customerTaxID").length > 0) {
        var isCustomerTaxIDValid = $form.validate().element("#customerTaxID");
        isValidForm = isCustomerTaxIDValid;
    }

    return isValidForm;
}

/**
 * @function
 * @description Submit Billing Form
 * @param resolve
 * @param reject
 */
function submitBillingForm(data, actions) {
    var url = util.appendParamToURL(Urls.submitBillingForm, 'format', 'ajax');
    var $form = $('#dwfrm_billing');
    var applyName = $form.find('#billing-submit').attr('name');
    var options = {
        url: url,
        data: $form.serialize() + '&' + applyName + '=x',
        type: 'POST'
    };
    $.ajax(options).done(function (responseData) {
        if (!responseData || responseData.error) {
            actions.reject(data);
            if (responseData.redirectUrl) {
                window.location.href = responseData.redirectUrl;
            }
        } else if (responseData.orderNumber) {
            orderNumber = responseData.orderNumber ? responseData.orderNumber : '';
            actions.resolve(data);
        } else {
            actions.reject(data);
            var billingRedirecturl = util.appendParamToURL(Urls.billing, 'adyenComponentGeneralError', 'generalError');
            window.location.href = billingRedirecturl;
        }
    });
}

function failOrder(resultCode, paymentMethodType) {
    var url = util.appendParamToURL(Urls.FailOrderFromClientComponent, 'format', 'ajax');
    url = util.appendParamToURL(url, 'resultCode', resultCode);
    url = util.appendParamToURL(url, 'orderNumber', orderNumber);
    url = util.appendParamToURL(url, 'paymentMethodType', paymentMethodType);
    var options = {
        url: url,
        type: 'GET'
    };
    $.ajax(options).done(function (responseData) {
        if (responseData && responseData.orderFailed) {
            var failedMsg = 'Order ' + orderNumber + ' was failed';
            // window.console.log(failedMsg);
        }
    });
}

/**
 * @function
 * @description Update and redraw payment methods
 * @param resolve
 * @param reject
 */
function updatePaymentMethods() {
    var url = util.appendParamToURL(Urls.updatePaymentMethods, 'format', 'ajax');
    var $form = $('#dwfrm_billing');
    var applyName = $form.find('#billing-submit').attr('name');
    var options = {
        url: url,
        data: $form.serialize() + '&' + applyName + '=x',
        type: 'POST'
    };
    $.ajax(options).done(function (responseData) {
        if (responseData && responseData.error != undefined && !responseData.error) {
            // Remove adyen component tabs and containers
            $('.payment-method-tab[type="adyen-component"]').remove();
            $('.payment-method[id^="component_"]').remove();

            componentsObj = {};
            checkoutConfiguration = null;
            sessionsResponse = null;
            paymentMethodsResponse = null;
            checkout = null;
            window.sessionsResponse = responseData.sessionsResponse;
            window.Configuration = responseData.checkoutConfiguration;

            initializeBillingEvents();
        } else {
            // Just For Showing Error on Billing page
            var redirectUrl = util.appendParamToURL(Urls.showConfirmationFromComponent, 'resultCode', 'UpdatePaymentError');
            window.location.href = redirectUrl;
        }
    });
}

var styleObject = {
	base: {
		color: 'black',
		background: '#f7f7f7',
		fontSize: '12px',
		fontSmoothing: 'antialiased',
		fontFamily: 'neuzeit-grotesk, sans-serif, serif',
		fontWeight: '300',
		fontStyle: 'normal'
	},
	error: {
		color: '#b2595c'
	},
	placeholder: {
		color: '#707070'
	},
	validated: {
		color: '#000000'
	}
};

/**
 * @function
 * @description Initializes Adyen CSE  Billing events
 */
function initializeBillingEvents() {
    $('#billing-submit').on('click', function () {
        var isAdyenPOS = document.querySelector('.payment-method-options :checked').value === 'AdyenPOS';
        var isAdyen = document.querySelector('.payment-method-options :checked').value === 'AdyenComponent';

        if (isAdyenPOS) {
          document.querySelector('#dwfrm_adyPaydata_terminalId').value = document.querySelector('#terminalList').value;
          return true;
        }

        if (isAdyen) {
          var adyenPaymentMethod = document.querySelector('#adyenPaymentMethodName');
          var paymentMethodLabel = document.querySelector("#lb_".concat(selectedMethod)).innerHTML;
          adyenPaymentMethod.value = paymentMethodLabel;
          validateComponents();
          return showValidation();
        }
    });

    $(document).on('click', '.klarna-label', function (e) {
        e.preventDefault();
        var paymentId = $(this).data('payment-id');
        $('.klarna-label').removeClass('active');
        $(this).addClass('active');
        $('.klarna-checkout__button--pay').prop('disabled', false);
        $('#rb_klarna_all').val(paymentId);
        displaySelectedMethod(paymentId);
    });

    $(document).on('click', '.klarna-checkout__button--pay', function (e) {
        e.preventDefault();
        var activeOption = $('.klarna-payments_options .klarna-label.active').length;
        if (activeOption) {
            if (activeOption == 1) {
                var paymentId = $('.klarna-payments_options .klarna-label.active').data('payment-id');
                $('#rb_klarna_all').val(paymentId);
                displaySelectedMethod(paymentId);
            }
            var tabId = $('.klarna-label.active').data('klarna-tab');
            $('#' + tabId).find('.adyen-checkout__button--pay').trigger('click');
        }
    });

    if (!window.sessionsResponse) {
        return;
    }

    sessionsResponse = window.sessionsResponse;
    checkoutConfiguration = window.Configuration;

    checkoutConfiguration.onChange = function (state
    /* , component */
    ) {
    var type = state.data.paymentMethod.type;

    if (selectedMethod === "googlepay" && type === "paywithgoogle") {
        type = "googlepay";
    }

    isValid = state.isValid;

    if (!componentsObj[type]) {
        componentsObj[type] = {};
    }

    componentsObj[type].isValid = isValid;
    componentsObj[type].stateData = state.data;
    };

    // checkoutConfiguration.showPayButton = false;
    checkoutConfiguration.paymentMethodsConfiguration = {
    card: {
        hasHolderName: true,
        holderNameRequired: true,
        showPayButton: true,
        styles: styleObject,
        onBrand: function (state) {
            selectedBrand = state.brand;
        },
        beforeSubmit: function beforeSubmit(data, component, actions) {
            var isFormValid = validateBillingForm();
            if (isFormValid) {
                submitBillingForm(data, actions);
            } else {
                actions.reject(data);
            }
        }
    },
    paypal: {
        onInit: function onInit(data, actions) {
            // onInit is called when the button first renders.
            // Check if customerTaxIDExists exists
            var customerTaxIDExists = $("#customerTaxID").length > 0;
            if (customerTaxIDExists) {
                // Initial validation
                var isCustomerTaxIDValid = validateBillingForm();
                if (isCustomerTaxIDValid) {
                    actions.enable();
                } else {
                    actions.disable();
                }

                // Validation on change customerTaxID value
                $("#customerTaxID").on('change mouseleave focusout paste', function () {
                    var isCustomerTaxIDValid = validateBillingForm();
                    if (isCustomerTaxIDValid) {
                        actions.enable();
                    } else {
                        actions.disable();
                    }
                });
            } else {
                // Call actions.enable() to enable the button.
                actions.enable();
            }
        },
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    },
    giropay: {
        showPayButton: true,
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    },
    directEbanking: {
        showPayButton: true,
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    },
    klarna: {
        showPayButton: true,
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    },
    klarna_account: {
        showPayButton: true,
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    },
    klarna_paynow: {
        showPayButton: true,
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    },
    cartebancaire: {
        showPayButton: true,
        beforeSubmit: function beforeSubmit(data, component, actions) {
            submitBillingForm(data, actions);
        }
    }
    };

    checkoutConfiguration.session = {
    id: window.sessionsResponse.id,
    sessionData: window.sessionsResponse.sessionData
    };

    checkoutConfiguration.onPaymentCompleted = function (result, component) {
        console.info(result, component);
        // console.info('PaymentType = ' + component.props.name);
        if (result && result.resultCode) {
            var redirectUrl = util.appendParamToURL(Urls.showConfirmationFromComponent, 'resultCode', result.resultCode);
            redirectUrl = util.appendParamToURL(redirectUrl, 'orderNumber', orderNumber);

            if (component && component.props && component.props.type && component.props.type != 'card') {
                redirectUrl = util.appendParamToURL(redirectUrl, 'paymentMethodType', component.props.type);
            } else if (component && component.props && component.props.type && component.props.type == 'card' && selectedBrand) {
                redirectUrl = util.appendParamToURL(redirectUrl, 'paymentMethodType', selectedBrand);
            }
            window.location.href = redirectUrl;
        }
    };
    checkoutConfiguration.onError = function (error, component) {
        console.error(error.name, error.message, error.stack, component);
        if (error.name == 'CANCEL' && component.props.type == 'paypal') {
            failOrder(error.name, component.props.type);
        }
        // Just For Showing Error on Billing page
        // var redirectUrl = util.appendParamToURL(Urls.showConfirmationFromComponent, 'resultCode', 'PaymentError');
        // window.location.href = redirectUrl;
    };

    AdyenCheckout(checkoutConfiguration).then(function (adCheckout) {
        checkout = adCheckout;

        // Access the available payment methods for the session.
        console.log('Payment Methods Response: \n' + JSON.stringify(checkout.paymentMethodsResponse));

        paymentMethodsResponse = checkout.paymentMethodsResponse;

        renderGenericComponent();

        // Show payment methods block
        if (paymentMethodsResponse && paymentMethodsResponse.paymentMethods.length > 0) {
            $('div.payment-methods').show();
            $('div.additionalFields').show();
        }
    });
}

function renderGenericComponent() {
    var i, paymentMethod, firstPaymentMethod;

    unmountComponents();

    checkoutConfiguration.paymentMethodsResponse = paymentMethodsResponse.paymentMethods;

    if (sessionsResponse.amount) {
      checkoutConfiguration.amount = sessionsResponse.amount;
      checkoutConfiguration.paymentMethodsConfiguration.paypal.amount = sessionsResponse.amount;
    }

    if (sessionsResponse.countryCode) {
      checkoutConfiguration.countryCode = sessionsResponse.countryCode;
    }

    if (paymentMethodsResponse.storedPaymentMethods) {
      for (var i = 0; i < checkout.paymentMethodsResponse.storedPaymentMethods.length; i++) {
        paymentMethod = checkout.paymentMethodsResponse.storedPaymentMethods[i];

        if (paymentMethod.supportedShopperInteractions.includes('Ecommerce')) {
          renderPaymentMethod(paymentMethod, true, sessionsResponse.imagePath);
        }
      }
    }

    paymentMethodsResponse.paymentMethods.forEach(function (pm) {
      renderPaymentMethod(pm, false, sessionsResponse.imagePath);
    });
    firstPaymentMethod = document.querySelector('input[type=radio][name=brandCode]');
    var isPaymentMethodChecked = document.querySelector('input[type=radio][name$=_selectedPaymentMethodID]:checked');
    if (firstPaymentMethod && !isPaymentMethodChecked) {
        firstPaymentMethod.checked = true;
        displaySelectedMethod(firstPaymentMethod.value);
    }
}
/**
 * To avoid re-rendering components twice, unmounts existing components from payment methods list
 */
function unmountComponents() {
    var promises = Object.entries(componentsObj).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            val = _ref2[1];

        delete componentsObj[key];
        return resolveUnmount(key, val);
    });
    return Promise.all(promises);
}

function resolveUnmount(key, val) {
    try {
        return Promise.resolve(val.node.unmount("component_".concat(key)));
    } catch (e) {
        // try/catch block for val.unmount
        return Promise.resolve(false);
    }
}

function displaySelectedMethod(type) {
    selectedMethod = type;
    resetPaymentMethod();

    if (document.querySelector('#billing-submit')) {
        if (['scheme', 'paypal', 'paywithgoogle', 'googlepay', 'mbway', 'amazonpay', 'kcp_banktransfer', 'kcp_payco'].indexOf(type) > -1) {
            document.querySelector('#billing-submit').disabled = true;
        } else {
            document.querySelector('#billing-submit').disabled = false;
        }
    }

    document.querySelector("#component_".concat(type)).setAttribute('style', 'display:block');

    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    var componentInputId = '#component_payment_method_' + type;
    var $selectedComponentPaymentMethod = $(componentInputId);
    $selectedComponentPaymentMethod.prop('checked', true);
}

function resetPaymentMethod() {
    $('.additionalFields').hide();
}

function showValidation() {
    if (componentsObj[selectedMethod] && !componentsObj[selectedMethod].isValid) {
        componentsObj[selectedMethod].node.showValidation();
        return false;
    }

    return true;
}
/**
* Assigns stateData value to the hidden stateData input field
* so it's sent to the backend for processing
 */

function validateComponents() {
    var stateData;

    if (componentsObj[selectedMethod] && componentsObj[selectedMethod].stateData) {
        stateData = componentsObj[selectedMethod].stateData;
    } else {
        var type = document.querySelector("#component_".concat(selectedMethod, " .type")) ? document.querySelector("#component_".concat(selectedMethod, " .type")).value : selectedMethod;
        stateData = {
            paymentMethod: {
                type: type
            }
        };
        var brandElm = document.querySelector("#component_".concat(selectedMethod, " .brand"));

        if (brandElm && brandElm.value) {
            stateData.paymentMethod.brand = brandElm.value;
        }
    }

    document.querySelector('#adyenStateData').value = JSON.stringify(stateData);
}
/**
 * Contains fallback components for payment methods that don't have an Adyen web component yet
 */

function getFallback(paymentMethod) {
    var fallback = {
        giftcard: "\n      <input type=\"hidden\" class=\"brand\" name=\"brand\" value=\"".concat(paymentMethod.brand, "\"/>\n      <input type=\"hidden\" class=\"type\" name=\"type\" value=\"").concat(paymentMethod.type, "\"/>")
    };
    return fallback[paymentMethod.type];
}

function renderCheckoutComponent(storedPaymentMethodBool, checkout, paymentMethod, container, paymentMethodID) {
    if (storedPaymentMethodBool) {
        return createCheckoutComponent(checkout, paymentMethod, container, paymentMethodID);
    }

    var fallback = getFallback(paymentMethod);

    if (fallback) {
        var template = document.createElement('template');
        template.innerHTML = fallback;
        container.append(template.content);
        return;
    }

    return createCheckoutComponent(checkout, paymentMethod, container, paymentMethodID);
}

function getPersonalDetails() {
    var shippingAddress = sessionsResponse.shippingAddress;
    if (!shippingAddress) {
        return {};
    }

    return {
        firstName: shippingAddress.firstName,
        lastName: shippingAddress.lastName,
        telephoneNumber: shippingAddress.phone
    };
}

function createCheckoutComponent(checkout, paymentMethod, container, paymentMethodID) {
    try {
        var nodeData = Object.assign(paymentMethod, {
            data: Object.assign(getPersonalDetails(), {
                personalDetails: getPersonalDetails()
            }),
            visibility: {
                personalDetails: 'editable',
                billingAddress: 'hidden',
                deliveryAddress: 'hidden'
           }
        });
        if (paymentMethod.type == 'scheme' && sessionsResponse && sessionsResponse.allowedCreditCardBrands) {
            nodeData.brands = sessionsResponse.allowedCreditCardBrands;
        }
        var node = checkout.create(paymentMethod.type, nodeData);

        if (!componentsObj[paymentMethodID]) {
            componentsObj[paymentMethodID] = {};
        }

        componentsObj[paymentMethodID].node = node;
        return node;
    } catch (e) {} // eslint-disable-line no-empty

    return false;
}

function getInstallmentValues(maxValue) {
    var values = [];

    for (var i = 1; i <= maxValue; i += 1) {
        values.push(i);
    }

    return values;
}

function setInstallments(amount) {
    try {
        if (installmentLocales.indexOf(window.Configuration.locale) < 0) {
            return;
        }

        var _ref4 = window.installments ? window.installments.replace(/\[|]/g, '').split(',') : [null, null],
        _ref5 = _slicedToArray(_ref4, 2),
        minAmount = _ref5[0],
        numOfInstallments = _ref5[1];

        if (minAmount <= amount.value) {
            checkoutConfiguration.paymentMethodsConfiguration.card.installmentOptions = {
                card: {}
            }; // eslint-disable-next-line max-len

            checkoutConfiguration.paymentMethodsConfiguration.card.installmentOptions.card.values = getInstallmentValues(numOfInstallments);
            checkoutConfiguration.paymentMethodsConfiguration.card.showInstallmentAmounts = true;
        }
    } catch (e) {} // eslint-disable-line no-empty
}

function getPaymentMethodID(isStored, paymentMethod) {
    if (isStored) {
        return "storedCard".concat(paymentMethod.id);
    }

    if (paymentMethod.brand) {
        // gift cards all share the same type. Brand is used to differentiate between them
        return "".concat(paymentMethod.type, "_").concat(paymentMethod.brand);
    }

    return paymentMethod.type;
}

function renderPaymentMethod(paymentMethod, storedPaymentMethodBool, path) {
    var paymentMethodsUI = document.querySelector('.payment-method-options');
	var paymentMethods = document.querySelector('.payment-methods .fieldset');
    var paymentTab = document.createElement('div');
    var klarnaPaymentTab = document.createElement('div');
    var paymentMethodID = getPaymentMethodID(storedPaymentMethodBool, paymentMethod);
    var klarnaMethodID = "klarna_all";
    var isSchemeNotStored = paymentMethod.type === 'scheme' && !storedPaymentMethodBool;
    var paymentMethodImage = paymentMethod.brand ? Urls.adyenPaymentImages + paymentMethod.brand + "-logo.png" : Urls.adyenPaymentImages + paymentMethod.type + "-logo.png";
    if (paymentMethodID === 'klarna' || paymentMethodID === 'klarna_account' || paymentMethodID === 'klarna_paynow') {
		paymentMethodImage = paymentMethod.brand ? Urls.adyenPaymentImages + paymentMethod.brand + "-logo.svg" : Urls.adyenPaymentImages + paymentMethod.type + "-logo.svg";
	}
    var cardImage = "".concat(path, "card.png");
    var imagePath = isSchemeNotStored ? cardImage : paymentMethodImage;
	var liContents = '<input name="brandCode" type="radio" value="' + paymentMethodID + '" id="rb_' + paymentMethodID + '"><label id="lb_' + paymentMethodID + '" for="rb_' + paymentMethodID + '"><img class="paymentMethod_img" src="' + imagePath + '"/></label>';
	if (paymentMethod.type === 'scheme' || paymentMethodID === 'scheme') {
		liContents = '<input name="brandCode" type="radio" value="' + paymentMethodID + '" id="rb_' + paymentMethodID + '"><label id="lb_' + paymentMethodID + '" for="rb_' + paymentMethodID + '"><span class="label-text">' + Resources.ADYEN_CREDIT_CARD_TEXT + '</span></label>';
	}
	liContents += '<input type="radio"' + 'id="component_payment_method_' + paymentMethodID + '"style="display:none" name="dwfrm_billing_paymentMethods_selectedPaymentMethodID" value="AdyenComponent">';
    var container = document.createElement('div');
	var node = renderCheckoutComponent(storedPaymentMethodBool, checkout, paymentMethod, container, paymentMethodID);
	paymentTab.classList.add('payment-method-tab', 'form-row', 'label-inline', paymentMethodID);
	paymentTab.setAttribute('type', 'adyen-component');
	paymentTab.setAttribute('data-method', paymentMethodID);
	paymentTab.innerHTML = liContents;
    container.classList.add('payment-method');
    container.setAttribute('id', "component_".concat(paymentMethodID));
    container.setAttribute('data-method', paymentMethodID);
	if (paymentMethodID === 'paypal') {
		var paypalTextContent = '<p class="payment-method-text">' + Resources.ADYEN_PAYPAL_TEXT + '</p>';
		container.innerHTML = paypalTextContent;
	}

    if (paymentMethod.type === 'klarna') {
        $('#klarna-payments_options').append($('#asset__klarna-paylater').html());
        container.innerHTML += $('#asset__klarna-paylater').html()
    }
    if (paymentMethod.type === 'klarna_account') {
        $('#klarna-payments_options').append($('#asset__klarna-payovertime').html());
        container.innerHTML += $('#asset__klarna-payovertime').html()
    }
    if (paymentMethod.type === 'klarna_paynow') {
        $('#klarna-payments_options').append($('#asset__klarna-paynow').html());
        container.innerHTML += $('#asset__klarna-paynow').html()
    }

    paymentMethods.append(container);
	var existingOptionTab = $('.payment-method-options.emea-options .payment-method-tab').last();
	if (existingOptionTab.length > 0) {
		existingOptionTab.after(paymentTab);
	} else {
		paymentMethodsUI.prepend(paymentTab);
	}

    if (paymentMethod.type !== 'paywithgoogle') {
        node && node.mount(container);
    } else {
        node.isAvailable().then(function () {
            node.mount(container);
        })["catch"](function () {}); // eslint-disable-line no-empty
    }

    // Klarna UI updates
    if (paymentMethod.type === 'klarna' || paymentMethod.type === 'klarna_account' || paymentMethod.type === 'klarna_paynow') {
        if (!$('.klarna_all').length) {
            var klarnaLiContents = '<input name="brandCode" type="radio" value="' + klarnaMethodID + '" id="rb_' + klarnaMethodID + '"><label id="lb_' + klarnaMethodID + '" for="rb_' + klarnaMethodID + '"><img class="paymentMethod_img" src="' + imagePath + '"/></label>';
            var klarnaContainer = document.createElement('div');
            klarnaLiContents += '<input type="radio"' + 'id="component_payment_method_' + klarnaMethodID + '"style="display:none" name="dwfrm_billing_paymentMethods_selectedPaymentMethodID" value="AdyenComponent">';
            klarnaPaymentTab.classList.add('payment-method-tab', 'form-row', 'label-inline', klarnaMethodID);
            klarnaPaymentTab.setAttribute('type', 'adyen-component');
            klarnaPaymentTab.setAttribute('data-method', klarnaMethodID);
            klarnaPaymentTab.innerHTML = klarnaLiContents;

            if (existingOptionTab.length > 0) {
                existingOptionTab.after(klarnaPaymentTab);
            } else {
                paymentMethodsUI.prepend(klarnaPaymentTab);
            }

            klarnaContainer.classList.add('payment-method');
            klarnaContainer.setAttribute('id', "component_".concat(klarnaMethodID));
            klarnaContainer.setAttribute('data-method', klarnaMethodID);

            klarnaContainer.innerHTML += $('#asset__klarna-payments').html()
            paymentMethods.append(klarnaContainer);
        }
    }

    var input = document.querySelector("#rb_".concat(paymentMethodID));

    if (input) {
        input.onchange = function () {
            displaySelectedMethod(event.target.value);
        }
    }

    if (paymentMethodID === 'giropay') {
        container.innerHTML = '';
    }

    if (componentsObj[paymentMethodID] && !container.childNodes[0] && ['bcmc', 'scheme'].indexOf(paymentMethodID) === -1) {
        componentsObj[paymentMethodID].isValid = true;
    }

    var selectedPaymentMethods = $('.payment-method.payment-method-expanded').length;
    if (selectedPaymentMethods == 0) {
        var firstPaymentMethod = $('.payment-method-tab').not('.not-selected').find('input[type="radio"]').first();
        if (firstPaymentMethod.length > 0) {
            firstPaymentMethod.click();
        }
    }

    if ($('#component_klarna_all .klarna-label').length == 1) {
        $('#component_klarna_all .klarna-label:first').addClass('active');
        $('.klarna-checkout__button--pay').prop('disabled', false);
    } else if ($('#component_klarna_all .klarna-label').length > 1)  {
        $('#component_klarna_all .klarna-label:first').removeClass('active');
        $('.klarna-checkout__button--pay').prop('disabled', true);
    }
}

/**
 * @function
 * @description Initializes Adyen CSE billing events
 */
exports.initBilling = function () {
    initializeBillingEvents();
};
