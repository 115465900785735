'use strict';

var minicart = require('../../../minicart'),
    progress = require('../../../progress'),
    util = require('../../../util'),
    TPromise = require('promise'),
    imagesLoaded = require('imagesloaded'),
    imageSliders = require('./imageSliders'),
    anchorScroll = require('./anchorScroll'),
    p1Content = require('./p1Content'),
    sliderRange = require('./sliderRange'),
    stickyprod = require('../stickyprod'),
    clubCompare = require('./clubCompare'),
    notifyme = require('../../../notifyme'),
    dialog = require('../../../dialog');

function getAcnchorElementPoition(hash) {
	var deviceSize;
	if (window.innerWidth < 992) {
		deviceSize = 10;
	}
	if (window.innerWidth > 991) {
		deviceSize = 87;
	}
	var scrollPosition = $(hash).offset().top - deviceSize;
	return scrollPosition;
};

function scrollOnPageLoad () {
	var hashLink = window.location.hash;
	if (hashLink.indexOf('&') === 0 && hashLink.indexOf('=') === 0) {
		if ($(hashLink).length) {
			$('html, body').animate({scrollTop: getAcnchorElementPoition(window.location.hash)}, 0);
		}
	}
};

function initLearnMoreAccordion () {
    $('.pdp-learn-more--top').accordion({
        heightStyle: 'content',
        collapsible: true,
        active: $('.showwhos-hide').length ? false : 0,
        animate: 200
    });

    $('.product-learnmore--container').removeClass('d-none');
}

var club = {
    init: function () {
        initEvents();
        initClubPageEvents();
        filterModelValues();
        clearFulfilledTabsStates();
        updateFulfilledTabs();
        checkNextBtnState();
        dynamicPriceUpdate();
        stickyprod.init();
        minicart.init();
        initDefaultVariant();
        initLearnMoreAccordion();
        initiPDPZoom();

		imagesLoaded('.club-pdp-main').on('always', function () {
			setTimeout(function () {
				$('html').removeClass('freeze-scrolling');
				$('.overlay-layer').remove();
				scrollOnPageLoad();
			}, 2500);
		});

        if ($('.radio-buttons-group .slider-range-wrapper').length === 0 && $('.radio-buttons-group.orientation').next('.radio-buttons-group').length > 0) {
            var allActiveDisabled = true;

            $('.orientation.radio-buttons-group').next('.radio-buttons-group').find('input[type="radio"], input[type="checkbox"]').each(function () {
                if (!$(this).is(':disabled')) {
                    allActiveDisabled = false;
                    return false;
                }
            });

            if (allActiveDisabled) {
                $('.orientation.radio-buttons-group .radio-button input[type="radio"]:checked')
                .siblings('.radio-button-label')
                .addClass('oos-disabled');
            }

            $('.orientation.radio-buttons-group .radio-button input[type="radio"]:not(:checked)').each(function () {
                checkOptionsAvailability($('.pdpForm'), $(this), true);
            });
        }
    }
};

var shaftTabFlag = false;
var gripTabFlag = false;
var reviewTabFlag = false;

// This boolean will toggle the shaft and grip functionality that adds those images to the main PDP image carousel when selected in the form on the right side
var shaftAndGripImageFunctionality = $('.pdpForm.club').data('shaft-grip-image');

// This boolean checks if it is wedge product
var isWedge = $('div[id="club-tab-container-club"].wedge').length > 0;

function checkNextBtnState() {
    var $activeTabLabel = $('.club-tab-label.active');
    var $requiredFields = $('.club-tab-container.' + $activeTabLabel.data('state')).find('.must-select');

    if ($requiredFields.length > $requiredFields.filter('.selected').length) {
        $activeTabLabel.closest('.pdpForm').find('#add-to-cart').addClass('button-inactive');
        $('.clubs-tab-labels-inner').addClass('tab-inactive');
    } else {
        $activeTabLabel.closest('.pdpForm').find('#add-to-cart').removeClass('button-inactive');
        $('.clubs-tab-labels-inner').removeClass('tab-inactive');
    }
}

function checkOptionsAvailability(form, $option, initial) {
    var uncheckedOrientationValue = $option.val();
    var configuration = sanitizeConfig(form);
    configuration.orientation = uncheckedOrientationValue;

    var pid = form.find('input[name="pid"]').val();
    if(pid === "0") {
        pid = $('#masterid').val();
    }
    var url = util.appendParamToURL(Urls.clubConfigurationUpdate, 'pid', pid);

    progress.show('.pdpForm');

    url = util.appendParamsToUrl(url, {
        'configuration': JSON.stringify(configuration)
    });

    $.ajax({
        type: 'GET',
        url: util.ajaxUrl(url),
        success: function (response) {
            var $response = $('<div>').html(response);

            var allDisabled = true;
            $response.find('.orientation.radio-buttons-group').next('.radio-buttons-group').find('input[type="radio"], input[type="checkbox"]').each(function () {
                if (!$(this).is(':disabled')) {
                    allDisabled = false;
                    return false;
                }
            });


            if (allDisabled) {
                if (initial) {
                    setTimeout(function () {
                        $option.siblings('.radio-button-label').addClass('oos-disabled');
                    }, 500);
                } else {
                    $option.siblings('.radio-button-label').addClass('oos-disabled');
                }
            }
            progress.hide('.pdpForm');
        },
        error: function () {
            console.error('Failed to check options availability');
            progress.hide('.pdpForm');
        }
    });
}

function initEvents () {
	if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
		setTimeout(scroll(0, 0), 1);
    }

	notifyme.init();
    // The container div for the Club Configurator Form.
    var configurator = $('#configurator');
    // User tries to move onto the next tab,
    // submit the data, and re-populate the form with new variation attribute for requested tab.
    configurator.on('click', '.add-to-cart', function (e) {
        e.preventDefault();
        if (!$(this).hasClass('button-inactive')) {
            $('.configurator-error-message').hide();
            var form = $('.pdpForm');
            var currentTab = $(e.currentTarget).data('state').current;
            var nextTab = $(e.currentTarget).data('state').next;
            if (form.hasClass('review')) {
                $(this).addClass('button-inactive').prop('disabled', true);
            }
            update(form, $(e.currentTarget)).then(function (response) {
                if (form.hasClass('review')) {
                    if (response.indexOf('max-quantity-error') >= 0) {
                        dialog.open({
                            url: Urls.maxQuantityError
                        });

                        $('#add-to-cart').removeClass('button-inactive').prop('disabled', false);
                    } else {
                        window.location.href = window.Urls.cartShow;
                    }
                } else {
                    sessionStorage.setItem('tabFulfilled' + currentTab, 'true');
                    configurator.html(enableNextButton(response));
                    filterModelValues();
                    clearFulfilledTabsStates();
                    updateFulfilledTabs();
                    dynamicPriceUpdate();
                    sliderRange.init();

                    var isGripNotAllSelected = $('#add-to-cart').hasClass('button-inactive') && $('#grip-brand').hasClass('selected') && $('#grip-type').hasClass('selected');
                    if (nextTab == 'grip' && isGripNotAllSelected) {
                        // should be called at the end
                        updateDefaultVariant();
                    }
                }
                progress.hide();
            });
        } else {
            $('.configurator-error-message').show();
            var $missedFields = configurator.find('.club-tab-container.active .must-select:not(.selected)');
            $missedFields.each(function () {
                if ($(this).is('select')) {
                    $(this).closest('.select-container').addClass('error');
                } else {
                    $(this).addClass('error');
                }
            });
        }
    });


    // User changes any selection.
    // If all required fields selected, make next button active
    configurator.on('change', 'select, .radio-buttons-group input[type="radio"], .radio-buttons-group input[type="checkbox"]', function () {
        var $this = $(this);
        var radioButtonsGroup = $this.closest('.radio-buttons-group');
        if ($this.hasClass('must-select') || radioButtonsGroup.length) {
            if ($this.hasClass('must-select')) {
                if (!$this.val()) {
                    $this.removeClass('selected');
                } else {
                    $this.addClass('selected');
                    $this.parent('.select-container').removeClass('error');
                }
            } else {
                radioButtonsGroup.addClass('selected');
                $this.closest('.radio-buttons-group').removeClass('error');
            }
            var $activeTab = $this.closest('.club-tab-container');
            var $requiredFields = $activeTab.find('.must-select');
            if ($requiredFields.length > $requiredFields.filter('.selected').length) {
                $activeTab.closest('.pdpForm').find('#add-to-cart').addClass('button-inactive');
                $('.clubs-tab-labels-inner').addClass('tab-inactive');
            } else {
                $activeTab.closest('.pdpForm').find('#add-to-cart').removeClass('button-inactive');
                $('.clubs-tab-labels-inner').removeClass('tab-inactive');
                $('.configurator-error-message').hide();
            }
        }
        filterModelValues();
        clearFulfilledTabsStates();
        updateFulfilledTabs();
    });

    configurator.on('click', '.js-preview__variant', function (e) {
        e.preventDefault();

        var $button = $(this);
        var $activeTab = $button.closest('.club-tab-container');
        $button.find('.js-preview__variant__value .js-preview__variant__value__label')
        $button.find('.js-preview__variant__value .js-preview__variant__value__designation')
        $button.find('.js-preview__variant__value .js-preview__variant__value__over-price')

        $activeTab.find('.js-preview__variant').removeClass('selected');
        $button.parents('.select-container').find('.club-attribute-select').val($button.val()).trigger('change');
        $activeTab.find('.js-preview__selected-value')
            .html('')
            .append($button.find('.js-preview__variant__value .js-preview__variant__value__label').clone())
            .append($button.find('.js-preview__variant__value .js-preview__variant__value__designation').clone())
            .append($button.find('.js-preview__variant__value .js-preview__variant__value__over-price').clone());
        $activeTab.find('.js-preview__collection').slideToggle('fast');
        $activeTab.find('.js-shaft-image-container').toggle();
        $button.addClass('selected');
    });

    configurator.on('click', '.js-preview__selected-value', function (e) {
        e.preventDefault();

        var $activeTab = $(this).closest('.club-tab-container');

        $activeTab.find('.js-preview__collection').slideToggle('fast', function () {
            if ($activeTab.find('.js-preview__variant.selected').length) {
                var scrollPosition = $activeTab.find('.js-preview__variant.selected').get(0).offsetTop - $activeTab.find('.js-preview__collection').get(0).offsetTop;
                $activeTab.find('.js-preview__collection').get(0).scrollTo({
                    top: scrollPosition
                });
            }
        });
        $activeTab.find('.js-shaft-image-container').toggle();
        $activeTab.find('.js-preview__selected-value').toggleClass('active');
    });

    // User changes any selection that requires the form to update.
    configurator.on('change', 'select.trigger-update, .radio-buttons-group.trigger-update input[type="radio"], .radio-buttons-group.trigger-update input[type="checkbox"]', function (e) {
        var allActiveDisabled = true;

        var target = $(e.currentTarget);
        var tab = target.parents('.club-tab-container');
        if (tab.hasClass('shaft active')) {
            $('#shaft-selected').val('true');
        }
        if (tab.hasClass('grip active')) {
            $('#grip-selected').val('true');
        }

        update($('.pdpForm')).then(function (response) {
            configurator.html(enableNextButton(response));

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }

            replaceImages();
            imageSliders.setupCarousel();
            filterModelValues();
            clearFulfilledTabsStates();
            updateFulfilledTabs();
            dynamicPriceUpdate();
            sliderRange.init();

            // should be called at the end
            updateDefaultVariant();

            if ($('.radio-buttons-group .slider-range-wrapper').length === 0 && $('.radio-buttons-group.orientation').next('.radio-buttons-group').length > 0) {
                $('.orientation.radio-buttons-group').next('.radio-buttons-group').find('input[type="radio"], input[type="checkbox"]').each(function () {
                    if (!$(this).is(':disabled')) {
                        allActiveDisabled = false;
                        return false;
                    }
                });

                if (allActiveDisabled) {
                    $('.orientation.radio-buttons-group .radio-button input[type="radio"]:checked')
                    .siblings('.radio-button-label')
                    .addClass('oos-disabled');
                }

                $('.orientation.radio-buttons-group .radio-button input[type="radio"]:not(:checked)').each(function () {
                    checkOptionsAvailability($('.pdpForm'), $(this), false);
                });
            }
        });
    });

    // User changes slider
    configurator.on("sliderStop", function (e, data) {
        update($('.pdpForm')).then(function (response) {
            configurator.html(enableNextButton(response));

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }

            replaceImages();
            imageSliders.setupCarousel();
            filterModelValues();
            clearFulfilledTabsStates();
            updateFulfilledTabs();
            dynamicPriceUpdate();
            sliderRange.init();

            // should be called at the end
            updateDefaultVariant();
        });
    });

    // User changes a Brand - ensure that the corresponding model is updated.
    // If current model doesn't match the currently selected brand,
    // update the form to remove selections to the model variation attributes.
    configurator.on('change', 'select.trigger-brand', function (e) {
        // Get the brand (manufacturer), from input data.
        var target = $(e.currentTarget);
        var tab = target.parents('.club-tab-container');
        var model = tab.find('select.trigger-model');
        var option = model.find('option:selected');
        var data = option.data('data');

        if (data && data.brandValue !== target.val()) {
            option.removeAttr('selected');
            model.find('option').each(function () {
                option = $(this);
                data = option.data('data');
                if (data && data.brandValue === target.val()) {
                    model.val(option.val());
                    model.trigger('change');
                    return false;
                }
            });

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }

            dynamicPriceUpdate();
        }
    });

    // User changes a Model (or Brand, which then changes Model) - ensure that the corresponding Brand is updated,
    // and that the form is updated by the server to reflect new variation attributes available.
    configurator.on('change', 'select.trigger-model', function (e) {

        // Get the brand (manufacturer), from input data.
        var target = $(e.currentTarget);
        var tab = target.parents('.club-tab-container');
        var brand = tab.find('select.trigger-brand');;
        var option = target.find('option:selected');
        var data = option.data('data');

        if (target.val() === '') {
            dynamicPriceUpdate();
            target.closest('.pdpForm').find('#add-to-cart').addClass('button-inactive');
            $('.clubs-tab-labels-inner').addClass('tab-inactive');

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }
        }

        if (data) {
            brand.val(data.brandValue);

            // Clear all hidden shaft input in case if we unselect shaft on shaft tab
            if (brand.val() === '' && tab.hasClass('shaft active')) {
                $('select[id^="shaft-"], input[id^="shaft-"]').val('');
                $('#shaft-selected').val('');
            } else if (brand.val() && brand.val().length > 0 && tab.hasClass('shaft active')) {
                // Clear all hidden shaft inputs except brand and model in case if we switch shaft on shaft tab
                $('select[id^="shaft-"]:not(#shaft-id,#shaft-brand), input[id^="shaft-"]').val('');
                $('#shaft-selected').val('true');
            }

            if (brand.val() === '' && tab.hasClass('grip active')) {
                $('#grip-selected').val('');
            } else if (brand.val() && brand.val().length > 0 && tab.hasClass('grip active')) {
                $('#grip-selected').val('true');
            }

            // Update the form with variation attributes for the current model,
            // and update the state of the continue button, if needed.
            update($('.pdpForm')).then(function (response) {
                configurator.html(enableNextButton(response));
                filterModelValues();
                clearFulfilledTabsStates();
                updateFulfilledTabs();
                dynamicPriceUpdate();
                sliderRange.init();

                if (shaftAndGripImageFunctionality) {
                    updateConfiguratorImages();
                }

                // should be called at the end
                updateDefaultVariant();
            });
        }
    });

    // User clicks an "Edit" link. Ensure that the form is rendered on the correct state.
    configurator.on('click', '.club-review-edit-link, .club-tab-label.fulfilled, .club-tab-label.enabled', function (e) {
        e.preventDefault();

        update($('.pdpForm'), $(e.currentTarget)).then(function (response) {
            configurator.html(enableNextButton(response));
            filterModelValues();
            clearFulfilledTabsStates();
            updateFulfilledTabs();
            dynamicPriceUpdate();
            sliderRange.init();
            progress.hide();
        });
    });

    configurator.on('click', '#resetFormValues', function (e) {
        e.preventDefault();
        sessionStorage.clear();
        $('.club-tab-container').each(function (i, tab) {
            var $activeForm = $(tab);
            $activeForm.find('option:selected').removeAttr('selected');
            $activeForm.find('input[type="radio"]:checked').removeAttr('checked');
            $activeForm.find('input[type="checkbox"]:checked').removeAttr('checked');
            $activeForm.find('.selected').removeClass('selected');
            $('.club-tab-label').removeClass('fulfilled');
            $activeForm.find('#club-setcomp').remove(); // it needs to empty all club-setcomp values for wedge or irons after configuration reset
            $activeForm.closest('.pdpForm').find('#add-to-cart').addClass('button-inactive');
            $('.clubs-tab-labels-inner').addClass('tab-inactive');
        });

        if (util.getViewport() < 990) {
            shaftTabFlag = false;
            gripTabFlag = false;
            reviewTabFlag = false;

            //scroll user to the tabs
            var scrollTabs = $('.clubs-tab-labels').offset().top;
            scrollToSection(scrollTabs);

        }

        update($('.pdpForm'), $(e.currentTarget)).then(function (response) {
            configurator.html(enableNextButton(response));
            filterModelValues();
            clearFulfilledTabsStates();
            updateFulfilledTabs();
            dynamicPriceUpdate();
            sliderRange.init();

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }

            initDefaultVariant();
        });

    });
}

function enableNextButton(form) {
    var data = $(form);
    var $activeTab = data.find('.club-tab-container.active');
    var $requiredFields = $activeTab.find('.must-select');
    if ($requiredFields.length > $requiredFields.filter('.selected').length) {
        $activeTab.closest('.pdpForm').find('#add-to-cart').addClass('button-inactive').removeAttr('disabled');
        $('.clubs-tab-labels-inner').addClass('tab-inactive');
    } else {
        $activeTab.closest('.pdpForm').find('#add-to-cart').removeClass('button-inactive').removeAttr('disabled');
        $('.clubs-tab-labels-inner').removeClass('tab-inactive');
    }
    return data;
}

// Take the serialized array, and convert it into a native object.
function sanitizeConfig(form) {
    var configuration = new Object(),
        serialArray = form.serializeArray(),
        filters = [
            'pid',
            'edit',
            'Quantity',
            'shaft-brand',
            'grip-brand'
        ];

    for (var i = 0; i < serialArray.length; i++) {
        if (filters.indexOf(serialArray[i].name) != -1) {
            continue;
        }
        if (serialArray[i].name === 'club-setcomp') {
            if (serialArray[i].value && serialArray[i].value !== 'null') {
                try {
                    configuration[serialArray[i].name] = JSON.parse(serialArray[i].value);
                } catch (e) {
                    configuration[serialArray[i].name] = serialArray[i].value;
                }
            }
        } else {
            configuration[serialArray[i].name] = serialArray[i].value;
        }
    }

    return configuration;
}

function initDefaultVariant () {
    var formState = $('.pdpForm #add-to-cart').data('state');

    // Update variant on club tab to show default variant properly
    // We need update select input in case if radio buttons selected by default
    if (formState && formState.current === 'club') {
        var availableCount = $('.radio-buttons-group.must-select').length;
        var selectedCount = $('.radio-buttons-group.must-select.selected').length;
        var isVariantSelectedByDefault = selectedCount > 0 && availableCount == selectedCount;
        if (isVariantSelectedByDefault) {
            var target;
            var options = {
                dontShowProgress: false
            };
            update($('.pdpForm'), target, options).then(function (response) {
                var configurator = $('#configurator');
                configurator.html(enableNextButton(response));
                filterModelValues();
                clearFulfilledTabsStates();
                updateFulfilledTabs();
                dynamicPriceUpdate();
                sliderRange.init();

                if ($('.radio-buttons-group .slider-range-wrapper').length === 0 && $('.radio-buttons-group.orientation').next('.radio-buttons-group').length > 0) {
                    var allActiveDisabled = true;

                    $('.orientation.radio-buttons-group').next('.radio-buttons-group').find('input[type="radio"], input[type="checkbox"]').each(function () {
                        if (!$(this).is(':disabled')) {
                            allActiveDisabled = false;
                            return false;
                        }
                    });

                    if (allActiveDisabled) {
                        $('.orientation.radio-buttons-group .radio-button input[type="radio"]:checked')
                        .siblings('.radio-button-label')
                        .addClass('oos-disabled');
                    }

                    $('.orientation.radio-buttons-group .radio-button input[type="radio"]:not(:checked)').each(function () {
                        checkOptionsAvailability($('.pdpForm'), $(this), true);
                    });
                }

                progress.hide();
            });
        } else {
            $('.orientation.radio-buttons-group .radio-button input[type="radio"]:not(:checked)').each(function () {
                checkOptionsAvailability($('.pdpForm'), $(this), true);
            });
        }
    }
};

var updateDefaultVariant = function () {
    var form = $('.pdpForm');
    var formState = form.find('#add-to-cart').data('state');
    var isVariantSelected = (form.find('#shaft-option-code').length > 0);
    var isGridBrandAndTypeSelected = $('#grip-brand').hasClass('selected') && $('#grip-type').hasClass('selected');
    var isNextButtonActive = !$('#add-to-cart').hasClass('button-inactive');

    // Update variant on shaft tab if it's pre-filled but is not selected
    if (formState.current === 'shaft' && isNextButtonActive && !isVariantSelected) {
        var target;
        var options = {
            dontShowProgress: true
        };
        update($('.pdpForm'), target, options).then(function (response) {
            var configurator = $('#configurator');
            configurator.html(enableNextButton(response));
            filterModelValues();
            clearFulfilledTabsStates();
            updateFulfilledTabs();
            dynamicPriceUpdate();
            sliderRange.init();

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }

            progress.hide();
        });
    } else if (formState.current === 'grip' && (isNextButtonActive || (!isNextButtonActive && isGridBrandAndTypeSelected))) {
        var target;
        var options = {
            dontShowProgress: true
        };
        update($('.pdpForm'), target, options).then(function (response) {
            var configurator = $('#configurator');
            configurator.html(enableNextButton(response));
            filterModelValues();
            clearFulfilledTabsStates();
            updateFulfilledTabs();
            dynamicPriceUpdate();
            sliderRange.init();

            if (shaftAndGripImageFunctionality) {
                updateConfiguratorImages();
            }

            progress.hide();
        });
    } else {
        progress.hide();
    }
};

// The form values changed, and need to be updated, or the user has submitted the form.
// Reach out to the server to either re-populate the form, or add the PLI to basket.
var update = function (form, target, options) {
    var formState = form.find('#add-to-cart').data('state');
    var renderState;
    var configuration = sanitizeConfig(form);

    var pid = typeof target !== 'undefined' && target.hasClass('reset-form') && $('#masterid').val() ? $('#masterid').val() : form.find('input[name="pid"]').val();
    // sometimes missing variations mean pid gets set to "0", so handle that
    if(pid === "0") {
        pid = $('#masterid').val();
    }
    var url = util.appendParamToURL(Urls.clubConfigurationUpdate, 'pid', pid);
    var isEdit = $('#configurator #edit').val() || false;
    var dontShowProgress = (options && options.dontShowProgress) ? options.dontShowProgress : false;
    var showProgress = !dontShowProgress;

    if (showProgress) {
        progress.show('.pdpForm');
    }

    // If the user submitted the form, they are expecting the next tab/state.
    // Else they have opted to edit their prior selection. Ensure the form displays the requested tab.
    // Otherwise let the server know that we are expecting a refresh.
    if (typeof target !== 'undefined') {
        // If the form is submitted by an edit button button, we expect the requeseted tab.
        if (target.hasClass('club-review-edit-link') || target.hasClass('club-tab-label') || target.hasClass('reset-form')) {
            renderState = target.data('state');
        } else {
            // It was submited by the submit button, and we expect the next tab.
            renderState = formState.next;
            if (form.hasClass('review')) {
                // Add to Cart
                url = util.appendParamsToUrl(form.attr('action'),
                    {
                        'pid': form.find('input[name="pid"]').val(),
                        'Quantity': form.find('select[name="Quantity"]').val()
                    }
                );
            }
        }
    } else {
        // Otherwise, a user selected an option that requires a refresh of variation options.
        renderState = formState.current;
    }

    // Pass the configuration object to the server for processing.
    url = util.appendParamsToUrl(url,
        {
            'configuration': JSON.stringify(configuration),
            'renderState': renderState,
            'edit': isEdit
        }
    );

    // Add UUID and quantity to URL if editing a product line item already in cart
    var $editUUID = $('#editUUID');
    var $editQuantity = $('#editQuantity');
    if ($editUUID.length) {
        url = util.appendParamToURL(url, 'Quantity', $editQuantity.val());
        url = util.appendParamToURL(url, 'uuid', $editUUID.val());
    }

    return TPromise.resolve($.ajax({
        type: 'GET',
        url: util.ajaxUrl(url)
    }));
};

function initClubPageEvents () {
    var $pdpMain = $('.club-pdp-main');

    $pdpMain.find('#add-to-cart').prop('disabled', false);

    imagesLoaded('.product-highlights').on('always', function () {
        $('.highlights-carousel').slick({
            mobileFirst: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
    });

    imageSliders.setupCarousel();
    imageSliders.setupRecCarousel();
    anchorScroll.initAnchorScroll();
    p1Content.init();
    sliderRange.init();
    clubCompare.init();

    $pdpMain.on('click', '.button-play-video', function (e) {
        e.preventDefault();
        var videoID = $(this).data('videoId');
        var videoPlayerID = 'video-player-' + videoID;
        var $videoModal = $(
            '<div class="highlight-video-modal">' +
            '<a href="#" class="close-video-player">×</a>' +
            '<div id="' + videoPlayerID + '" class="highlight-video-player"></div>' +
            '</div>'
        );
        var player;
        $videoModal.appendTo('body');
        player = new YT.Player(videoPlayerID, {
            height: '360',
            width: '640',
            videoId: videoID,
            playerVars: {
                autoplay: 1,
                modestbranding: 1,
                autohide: 1,
                rel: 0,
                showinfo: 0,
                controls: 1
            },
            events: {
                'onReady': onPlayerReady
            }
        });
        function onPlayerReady(event) {
            event.target.playVideo();
            $('.close-video-player').on('click', function (e) {
                e.preventDefault();
                player.stopVideo();
                $(this).closest('.highlight-video-modal').remove();
            });
        }

    });

    $pdpMain.on('click', '.spec-tab-item a', function (e) {
        e.preventDefault();
        var targetSectionID = $(this).attr('href');
        $(this).closest('.spec-tab-items').find('.spec-tab-item').removeClass('active');
        $(this).closest('.spec-tab-item').addClass('active');
        $(this).closest('.product-spec').find('.spec-tab-content').removeClass('active');
        $(this).closest('.product-spec').find(targetSectionID).addClass('active');
    });

    $pdpMain.on('click', '[id^=club-setcomp]', function () {
        var setCompValues = [];
        if ($(this).attr('type') === 'radio') {
            $('[id^=club-setcomp]:checked').prop("checked", false);
            $(this).prop("checked", true);
            $('[name=club-setcomp]').val($(this).val());
        } else {
            if (!isWedge) {
                $('[id^=club-setcomp]:checked').each(function (i, el) {
                    setCompValues.push($(el).val());
                });
                $('[name=club-setcomp]').val(JSON.stringify(setCompValues));
            } else {
                var clubSetcompValueStr = $('#club-setcomp').val();
                var selectedClubSetcompArray = [];
                try {
                    var parsedClubSetcompValue = JSON.parse(clubSetcompValueStr);
                    if (parsedClubSetcompValue) {
                        selectedClubSetcompArray = parsedClubSetcompValue;
                    }
                } catch (e) {
                    // if "clubSetcompValueStr" contains only one setcomp which is not in array
                    selectedClubSetcompArray.push(clubSetcompValueStr);
                }

                var selectedSetcompValue = $(this).val();

                var isClubSetcompChecked = $(this).is(':checked');
                if (isClubSetcompChecked) {
                    selectedClubSetcompArray.push(selectedSetcompValue);
                } else {
                    var index = selectedClubSetcompArray.indexOf(selectedSetcompValue);
                    if (index > -1) { // only splice array when item is found
                        selectedClubSetcompArray.splice(index, 1);
                    }
                }

                var selectedClubSetcompArrayStr = JSON.stringify(selectedClubSetcompArray);
                $('#club-setcomp').val(selectedClubSetcompArrayStr);
            }
        }
    });

    // Handler for checking if all clubs attributes are selected then only oos badge should be removed
    $(document).on('click', '.isClubAttribute:not(.selected) input', function () {
        var clubAttributeCount = $('#club-tab-container-club .isClubAttribute').length;
        var selectedClubAttributeCount = $('#club-tab-container-club .isClubAttribute.selected').length + 1;
        if (clubAttributeCount != 0 && clubAttributeCount == selectedClubAttributeCount) {
            $('div[data-type ="oos"].badge-wrapper').remove();
        }
    });

    util.smartResize(function () {
        var $mainSlider = $('.product-image-container');
        var $shaftAndGripSlides = $mainSlider.find('.slick-slide.grip-img .product-image, .slick-slide.shaft-img .product-image');
        $shaftAndGripSlides.css('height', 'auto');

        setTimeout(function () {
            $shaftAndGripSlides.css('height', $mainSlider.outerHeight());
        }, 10);
    });

    if ($('#club-tab-container-club > *').length === $('#club-tab-container-club > .selected').length) {
        // If the club PDP loads with options selected by default, enable the CTA button
        $pdpMain.find('#add-to-cart').removeClass('button-inactive').removeAttr('disabled');
        $('.clubs-tab-labels-inner').removeClass('tab-inactive');
    }

    if ($('.finish-select').length && !$('.radio-buttons-container .selected').length) {
        $('.finish-select').find('.selectable:first').trigger('click');
    }

    if ($('.disable-commerce').length) {
        $('.mobile-product-info').addClass('no-comm');
    }
}

var initiPDPZoom = function () {
    if($('#js-zoom-content').length > 0) {
        $('#pdpMain').addClass('zoom-enabled');
    }

    $('#pdpMain.zoom-enabled.club-pdp-main').on('click', '.product-primary-image', function (e) {
        e.preventDefault();
        if (e.target.classList.contains('slick-arrow') || e.target.classList.contains('design-your-own-ball')) {
            return;
        }
        var $zoomContent = $('#js-zoom-content');
        $('body').addClass('fixedPosition');
        $zoomContent.dialog({
            dialogClass: 'zoom-image-dialog',
            modal: true,
            draggable: false,
            resizable: false,
            width: $(window).width(),
            height: $(window).height(),
            left: 0,
            top: 0,
            open: function () {
                $('.zoom-content').removeClass('hidden');
            },
            close: function () {
                $('.zoom-content').addClass('hidden');
                $(this).dialog('destroy');
                $('body').removeClass('fixedPosition');
            }
        });
    });
}

var replaceImages = function () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }
    $imageContainer.html($newImages.html());

    if (!shaftAndGripImageFunctionality) {
        $newImages.remove();
    }
    initiPDPZoom();
};

var updateConfiguratorImages = function () {
    var $image;
    if ($('.club-tab-label.shaft.active').length || $('.club-tab-label.shaft.fulfilled').length) {
        if ($('#shaft-id option:selected').val() === "" || $('#shaft-brand option:selected').val() === "") {
            // Remove shaft carousel images
            $('#update-images .thumbnails-container .shaft-img').remove();
            $('#update-images .regular .shaft-img').remove();
            // reset last thumb
            $('#update-images .thumbnails-container .thumb').addClass('last-thumb');
            $('#update-images .regular .thumb').last().addClass('last-thumb');
        } else {
            // Insert images into carousel here
            $image = $('.club-tab-container.shaft .shaft-image-container img');
            var shaftImg = $image.attr('src');
            var shaftHiresImg = $image.data('hires') ? $image.data('hires') : $image.attr('src');
            var $thumbImgHtml = '<li class="thumb shaft-img last-thumb">' +
            '<a class="thumbnail-link">' +
                '<img class="productthumbnail" src="' + shaftImg + '">' +
            '</a></li>';

            var $mainImgHtml = '<div class="thumb shaft-img last-thumb">' +
            '<div class="product-image">' +
                '<img class="primary-image" src="' + shaftHiresImg + '">' +
            '</div></div>';

            $('#update-images .thumbnails-container .last-thumb').removeClass('last-thumb').after($thumbImgHtml);
            $('#update-images .regular .last-thumb').removeClass('last-thumb').after($mainImgHtml);
        }
    }

    if ($('.club-tab-label.grip.active').length || $('.club-tab-label.grip.fulfilled').length) {
        if ($('#grip-type option:selected').val() === "" || $('#grip-brand option:selected').val() === "") {
            // Remove grip carousel images
            $('#update-images .thumbnails-container .grip-img').remove();
            $('#update-images .regular .grip-img').remove();
            // reset last thumb
            $('#update-images .thumbnails-container .thumb').addClass('last-thumb');
            $('#update-images .regular .thumb').last().addClass('last-thumb');
        } else {
            // Insert images into carousel here
            var $gripImg = $('.club-tab-container.grip .shaft-image-container img').attr('src');
            var $gripHiresImg = $('.club-tab-container.grip .shaft-image-container img').data('hires');
            var $thumbImgHtml = '<li class="thumb grip-img last-thumb">' +
            '<a class="thumbnail-link">' +
                '<img class="productthumbnail" src="' + $gripImg + '">' +
            '</a></li>';

            var $mainImgHtml = '<div class="thumb grip-img last-thumb">' +
            '<div class="product-image">' +
                '<img class="primary-image" src="' + $gripHiresImg + '">' +
            '</div></div>';

            $('#update-images .thumbnails-container .last-thumb').removeClass('last-thumb').after($thumbImgHtml);
            $('#update-images .regular .last-thumb').removeClass('last-thumb').after($mainImgHtml);
        }
    }

    // Reinitialize slick
    replaceImages();
    imageSliders.setupCarousel();
};

var scrollToSection = function (scrollPos) {
    $('html, body').animate({
        scrollTop: scrollPos
    }, 1000);
};

function updateFulfilledTabs() {
    $('.club-tab-label').each(function () {
        if (sessionStorage.getItem('tabFulfilled' + $(this).data('state')) === 'true') {
            $(this).addClass('fulfilled');
        } else {
            $(this).removeClass('fulfilled');
        }
    });
    if ($('.club-tab-label.fulfilled').length === 3) {
        $('.club-tab-label.review').addClass('enabled');
    }
    if (util.getViewport() < 990) {

        // If a configurator tab is already complete and there have not been any selections in the current tab
        // then scroll user to the tabs
        if ($('.club-tab-label.active').length > 0) {
            var scrollTabs = $('.clubs-tab-labels').offset().top;
            if ($('#configurator .pdpForm').hasClass('shaft') && !shaftTabFlag) {
                shaftTabFlag = true;
                scrollToSection(scrollTabs);
            } else if ($('#configurator .pdpForm').hasClass('grip') && !gripTabFlag) {
                gripTabFlag = true;
                scrollToSection(scrollTabs);
            } else if ($('#configurator .pdpForm').hasClass('review') && !reviewTabFlag) {
                reviewTabFlag = true;
                scrollToSection(scrollTabs);
            }
        }
    }
}

function clearFulfilledTabsStates() {
    $('.club-tab-label').each(function () {
        sessionStorage.setItem('tabFulfilled' + $(this).data('state'), $(this).hasClass('fulfilled'));
    });
}

function filterModelValues() {
    var $currentTabContainer = $('.club-tab-container.active');

    var $brandSelector = $currentTabContainer.find('.trigger-brand');
    if ($brandSelector.length) {
        var $modelSelector = $currentTabContainer.find('.trigger-model');
        var modelSelectorValue = $modelSelector.val();
        $modelSelector.html($modelSelector.next().html());
        $modelSelector.val(modelSelectorValue);
        var selectedBrand = $brandSelector.val();

        var $shaftVariant = $('.js-preview__variant');

        if (selectedBrand) {
            $shaftVariant.show();

            var $modelOptionsToHide = $modelSelector.find('option').filter(function () {
                return $(this).data('data') && $(this).data('data').brandValue !== '' && $(this).data('data').brandValue !== selectedBrand;
            });
            $modelOptionsToHide.remove();

            var $shaftVariantsToHide = $shaftVariant.filter(function () {
                return $(this).data('data') && $(this).data('data').brandValue !== '' && $(this).data('data').brandValue !== selectedBrand;
            });
            $shaftVariantsToHide.hide();

            var $selectedValue = $('.js-preview__variant[value="' + modelSelectorValue + '"] .js-preview__variant__value');
            $('.js-preview__selected-value')
                .html('')
                .append($selectedValue.find('.js-preview__variant__value__label').clone())
                .append($selectedValue.find('.js-preview__variant__value__designation').clone())
                .append($selectedValue.find('.js-preview__variant__value__over-price').clone());
        }
        if ($modelSelector.val() === '') {
            $modelSelector.closest('.pdpForm').find('#add-to-cart').addClass('button-inactive');
            $('.clubs-tab-labels-inner').addClass('tab-inactive');
        }
    }
}

function dynamicPriceUpdate() {
    var $models = $('.trigger-model');
    var $price = $('#configurator .product-price');
    var $standardPrice = $price.find('.price-standard');
    var standardPriceNew = $standardPrice ? $standardPrice.data('originalPrice') : null;
    var $salesPrice = $price.find('.price-sales');
    var multiplier = $('[id^=club-setcomp-count]').val() || 1;

    if ($salesPrice.length > 0) {
        var salesPriceNew = $salesPrice.data('originalPrice');
        var $finishPrices = $('#finishPrices');
        if (!salesPriceNew && $finishPrices && $finishPrices.length > 0) {
            try {
                var priceData = JSON.parse($finishPrices.attr('data-prices'));
                var finishValue = $('input[name="finish"]:checked').val();
                salesPriceNew = priceData[finishValue].price;
            } catch (e) {
            }
        }
        var currency = $price.data('currency');
        $models.each(function () {
            var $selectedOption = $(this).find('option:selected');
            if ($selectedOption && $selectedOption.data('data') && $selectedOption.data('data').upcharge) {
                var upcharge = $selectedOption.data('data').upcharge;
                if (standardPriceNew) {
                    standardPriceNew = standardPriceNew + upcharge;
                }
                salesPriceNew = salesPriceNew + upcharge;
            }
        });
        if (standardPriceNew) {
            if ($('.product-main-info .price-standard').length > 0) {
                $('.product-main-info .price-standard').html(currency + (standardPriceNew * multiplier).toLocaleString('ja-JP', {
                    currency: 'JPY'
                }));
            } else {
                $('.price-sales').not('.mini-bag-price').parent().find('.price-standard').remove(); // remove already created standard price elements
                var formatedPrice = (standardPriceNew * multiplier).toLocaleString('ja-JP', {
                    currency: 'JPY'
                });
                var standardPriceNewText = currency + formatedPrice;
                var priceHtmlElement = '<span class="price-standard" data-original-price="' + standardPriceNew + '">' + standardPriceNewText + '</span>';
                $('.price-sales').not('.mini-bag-price').parent().prepend(priceHtmlElement);
            }
        } else {
            $('.product-main-info .price-standard').remove();
        }
        if (salesPriceNew) {
            var formatedSalePrice = (salesPriceNew * multiplier).toLocaleString('ja-JP', {
                currency: 'JPY'
            });
            $('.price-sales').not('.mini-bag-price').html(currency + formatedSalePrice);
            var gtmAddToCartDataStr = $('#add-to-cart').attr('data-gtmdata');
            if (gtmAddToCartDataStr && gtmAddToCartDataStr !== 'null') {
                var gtmAddToCartData = JSON.parse(gtmAddToCartDataStr);
                gtmAddToCartData.price = salesPriceNew.toString();
                $('#add-to-cart').attr('data-gtmdata', JSON.stringify(gtmAddToCartData));
            }
        }
    }
}

module.exports = club;
