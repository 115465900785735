'use strict';

var giftcert = require('../giftcert'),
	tooltip = require('../tooltip'),
	util = require('../util'),
	dialog = require('../dialog'),
	page = require('../page'),
    imageSliders = require('./product/custom/imageSliders'),
	validator = require('../validator'),
	productTile = require('../product-tile'),
	hellmannAVS = require('../hellmannAVS'),
	headingToggleButton = require('./account/headingToggleButton'),
	quickview = require('../quickview'),
	wishlist = require('./account/wishlist'),
    melissa = require('./checkout/melissa');

var contactus = require('./contactus');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
	var $form = $('#edit-address-form');
	var currentCountryCode = $form.data('store-locale').slice(-2);
    $('.user-taken').hide();
	$form.find('input[name="format"]').remove();
	$form.find('input[name="dwfrm_profile_address_addressid"]').blur();
	tooltip.init();
    melissa.init();
	//$('<input/>').attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);
	$form.on('click', '.apply-button', function (e) {
		e.preventDefault();
		$('input[name="hasError"]').val(false);
		if (!$form.valid()) {
			return false;
		}
		var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
		var applyName = $form.find('.apply-button').attr('name');
		var options = {
			url: url,
			data: $form.serialize() + '&' + applyName + '=x',
			type: 'POST'
		};
		$.ajax(options).done(function (data) {
			if (!$(data).find('input[name="hasError"]').length) {
				dialog.close();
				page.refresh();
			} else {
				$('#dialog-container').html(data);
				initAddressEvents();
				initializeAddressForm();
			}
			/*if (typeof(data) !== 'string') {
				if (data.success) {
					dialog.close();
					page.refresh();
				} else {
					window.alert(data.message);
					return false;
				}
			} else {
				$('#dialog-container').html(data);
				account.init();
				tooltip.init();
			}*/
		});
	})
	.on('click', '.cancel-button, .close-button', function (e) {
		e.preventDefault();
		dialog.close();
	})
	.on('click', '.delete-button', function (e) {
		e.preventDefault();
		if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
			var url = util.appendParamsToUrl(Urls.deleteAddress, {
				AddressID: $form.find('#addressid').val(),
				format: 'ajax'
			});
			$.ajax({
				url: url,
				method: 'POST',
				dataType: 'json'
			}).done(function (data) {
				if (data.status.toLowerCase() === 'ok') {
					dialog.close();
					page.refresh();
				} else if (data.message.length > 0) {
					window.alert(data.message);
					return false;
				} else {
					dialog.close();
					page.refresh();
				}
			});
		}
	});
	$('#edit-address-form .input-select.country').on('change', function (e) {
		util.updateState($(this));
	});
	$('#edit-address-form .input-select.country').trigger('change');
	validator.init();
	if (currentCountryCode == 'US' || currentCountryCode == 'CA') {
        $('.phone').mask('(999) 999-9999');
	}
}

function checkUser() {
    if ($('.form-row--dwfrm_profile_customer_email .form-caption.error-message').length) {
        $('.user-taken').show();
    }
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
	$('.order-items')
		.find('li.hidden:first')
		.prev('li')
		.append('<a class="toggle">View All</a>')
		.children('.toggle')
		.click(function () {
			$(this).parent().siblings('li.hidden').show();
			$(this).remove();
		});
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addressForm = $('#edit-address-form');
    checkUser();
    if (addressForm.length !== 0) {
        initializeAddressForm();
    }

	var addresses = $('#addresses');
	if (addresses.length === 0) { return; }

	addresses.on('click', '.address-edit, .address-create', function (e) {
		e.preventDefault();
		var triggeredLink = $(this);
		dialog.open({
			url: this.href,
			options: {
				dialogClass: 'add-address-dialog',
				open: function open() {
					initializeAddressForm();
					$(this).parent().attr('tabindex', '0');
					$(this).parent().focus();
				},
				close: function close() {
					triggeredLink.focus();
				}
			}
		});
	}).on('click', '.delete', function (e) {
		e.preventDefault();
		if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
			$.ajax({
				url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
				dataType: 'json'
			}).done(function (data) {
				if (data.status.toLowerCase() === 'ok') {
					page.redirect(Urls.addressesList);
				} else if (data.message.length > 0) {
					window.alert(data.message);
				} else {
					page.refresh();
				}
			});
		}
	});
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
	$('.add-card').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				dialogClass: 'add-card-dialog',
				open: initializePaymentForm
			}
		});
	});

	var paymentList = $('.payment-list');
	if (paymentList.length === 0) { return; }

	util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

	$('form[name="payment-remove"]').on('submit', function (e) {
		e.preventDefault();
		// override form submission in order to prevent refresh issues
		var button = $(this).find('.delete');
		$('<input/>').attr({
			type: 'hidden',
			name: button.attr('name'),
			value: button.attr('value') || 'delete card'
		}).appendTo($(this));
		var data = $(this).serialize();
		$.ajax({
			type: 'POST',
			url: $(this).attr('action'),
			data: data
		})
		.done(function () {
			page.redirect(Urls.paymentsList);
		});
	});

}

/**
 * @function
 * @description Initializes the events on the payment instrument form (apply, cancel)
 * @param {Element} form The form which will be initialized
 */
function initializePaymentForm() {
	var $form = $('#CreditCardForm');
	//hide address ID from Credit Card Form (redmine #709271)
	$('input[id$="_addressid"]').parents('.form-row').hide();
	tooltip.init();
	$form.on('click', '#applyBtn', function (e) {
		e.preventDefault();
		var creditcardNumberFieldName = $('input[name*="newcreditcard_number"]').attr('name');
		$('input[name="hasError"]').val(false);
		if (!$form.valid()) {
			return false;
		}
		var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
		var applyName = $form.find('#applyBtn').attr('name');
		var options = {
			url: url,
			data: $form.serialize() + '&' + applyName + '=x' + '&creditcard_number_field_name=' + creditcardNumberFieldName,
			type: 'POST'
		};
		$.ajax(options).done(function (data) {
			if (!$(data).find('input[name="hasError"]').length) {
				dialog.close();
				page.refresh();
			} else {
				$('#dialog-container').html(data);
				initializePaymentForm();
			}
		});
	})
	.on('click', '.cancel-button, .close-button', function (e) {
		e.preventDefault();
		dialog.close();
	});
	$('input[name$="_firstname"]').parents('.form-row').addClass('firstname');
	$('#CreditCardForm .input-select.country').trigger('change');
	validator.init();
}

/**
 * @private
 * @function
 * @description init events for the profilePage
 */
function initProfilePage() {
    if ($('.account-navon-oh').length) {
        $(".secondary-navigation--custom .content-asset  ul li.li-order").addClass('selected');
    }

    if ($('.account-navon-wl').length > 0) {
        $(".secondary-navigation--custom .content-asset  ul li.li-wl").addClass('selected');
    }

	//disable email/password fields
	if ($('.registered-form').hasClass('registered')) {
		$('[name="dwfrm_profile_customer_email"], .side-form.newpassword input').attr('disabled', 'disabled');
	}

	if ($('.side-form.emailconfirm .form-row').hasClass('error')) {
		$('.side-form.emailconfirm').show();
	}
	if ($('.side-form.passwordconfirm .form-row').hasClass('error')) {
		$('.side-form.newpassword, .side-form.passwordconfirm').show();
		$('.side-form.newpassword input').removeAttr('disabled');
	}
	$('button.editLogin').on('click', function (e) {
		e.preventDefault();
		//clean any errors
		$('[name="dwfrm_profile_customer_email"]').removeAttr('disabled');
		$('[name="dwfrm_profile_customer_email"]').focus();
		$('[name="dwfrm_profile_customer_email"], .side-form.emailconfirm input').val('');
		$('.side-form.password input').val('').attr('disabled', null);
		$('.side-form.emailconfirm').show();
	});
	$('button.changePassword').on('click', function (e) {
		e.preventDefault();
		//clean any errors
		$('[name^="dwfrm_profile_login_password"], [name^="dwfrm_profile_login_newpassword"], [name^="dwfrm_profile_login_passwordconfirm"]').removeAttr('disabled');
		$('.side-form.newpassword input').focus();
		$('[name^="dwfrm_profile_login_password"], [name^="dwfrm_profile_login_newpassword"]').val('');
		$('.side-form.newpassword, .side-form.passwordconfirm').show();
	});
}

/**
 * @private
 * @function
 * @description init events for the SOM Order History
 */
function initSOMOrderHistory() {
	var orderInterval = $('.som-order-history').data('orderinterval') && Math.floor($('.som-order-history').data('orderinterval')) > 2 ? Math.floor($('.som-order-history').data('orderinterval')) : 3;
	var orderBlock = $('.order-history-block') ? $('.order-history-block') : false;
	var l = $('.order-block-left') ? $('.order-block-left') : false;
	var r = $('.order-block-right') ? $('.order-block-right') : false;
	var maxRange = 0;
	var maxRangeDiv = $('.order-count-current-range-full') ? $('.order-count-current-range-full') : false;

	if (l) {
		orderBlock.each(function () {
			var t = $(this);
			maxRange += $(this).data('ordernumbers');

			if (t.data('index') !== 1) {
				t.hide();
			} else {
				var orderCount = t.find('.order-item').length;
				var min = ($(this).find('.order-item .order-count').first().data('ordercount'));
				var max = ($(this).find('.order-item .order-count').last().data('ordercount'));
				$('.order-count-current-range-min').text(min + '-');
				$('.order-count-current-range-max').text(max);
				$('.order-count-current-range').text(orderCount);
			}
		});

		if (maxRangeDiv) {
			maxRangeDiv.text(maxRange);
		}

		l.on('click', function (e) {
            e.preventDefault();
			orderBlock.each(function () {
				var t = $(this);
				if (t.data('index') > 1) {
					if (t.is(':visible')) {
						t.hide();
						t.prev('.order-history-block').show();
						return false;
					} else {
						t.hide();
					}
				}
			});

			orderBlock.each(function () {
				var t = $(this);

				if (t.is(':visible')) {
					var decrementedRange = ($(this).data('index') !== 1) ? $(this).data('ordernumbers') : 0;
					var min = ($(this).find('.order-item .order-count').first().data('ordercount'));
					var max = ($(this).find('.order-item .order-count').last().data('ordercount'));

					$('.order-count-current-range-min').text(min + '-');
					$('.order-count-current-range-max').text(max);
				}

			});
		});

		r.on('click', function (e) {
            e.preventDefault();
			orderBlock.each(function () {
				var t = $(this);

				if (t.data('index') !== orderBlock.length) {
					if (t.is(':visible')) {
						t.hide();
						t.next('.order-history-block').show();
						return false;
					} else {
						t.hide();
					}
				}
			});

			orderBlock.each(function () {
				var t = $(this);

				if (t.is(':visible')) {
					var min = ($(this).find('.order-item .order-count').first().data('ordercount'));
					var max = isNaN($(this).find('.order-item .order-count').last().data('ordercount')) ? parseInt(maxRange) : $(this).find('.order-item .order-count').last().data('ordercount');

					if (min === max) {
						$('.order-count-current-range-min').text(maxRange);
						$('.order-count-current-range-max').text('');
					} else {
						$('.order-count-current-range-min').text(min + '-');
						$('.order-count-current-range-max').text(max);
					}
				}

			});
		});
	}

    if ($('#dwfrm_legacyordersearch_orderNumber').length) {
        var placeHldr = $('.legacy-box').data("placeholder");
        $('#dwfrm_legacyordersearch_orderNumber').attr("placeholder", placeHldr);
    }

    $('.flex-details').on('click', '.order-title, .arrow-tog', function (e) {
        $(this).closest('.flex-details').toggleClass('opened');
    });

	$('#customOrderCancelForms').on('submit', function (e) {
		$(this).find('button[name="customOrderCancelSubmit"]').prop('disabled', 'disabled');
    });

	$('body').on('change', '#customOrderCancelForms .cancel-sel', function (e, param) {
		var qty = parseInt($(this).val());
		var price = parseFloat($('.cancel-item-price').text());
		var currency = $('.cancel-item-currency-symbol').text();
		var total = currency + (qty * price).toFixed(2);
		$('.cancel-total-price').text(total);
	});

    var $profileNavSelectedBtn = $(".secondary-navigation--custom .content-asset  ul li.selected");
    //console.log('length=' + $profileNavSelectedBtn.length);
    if ($profileNavSelectedBtn.length > 0) {
        $profileNavSelectedBtn[0].scrollIntoView({
            //behavior: "smooth", // or "auto" or "smooth"
            block: "end", // or "end"
            inline: "center" // or "end"
        });
    }
}

function initOGMySubscription() {
	$('.profile-columns').on('click', '.og-product .item-edit-details a:not(.configurable-product .item-edit-details a):not(.сustomizable-product .item-edit-details a)', function (e) {
		e.preventDefault();
		var ogProduct = $(this).closest('.og-product');
		var productHref = ogProduct.find('.og-product-name a').attr('href');
		var quantitySelect = ogProduct.find('.og-quantity select[name="quantity"]');
		var selectedQuantity = quantitySelect.val();
		var url = util.appendParamToURL(productHref, 'Quantity', selectedQuantity);
		url = util.appendParamToURL(url, 'subscriptionId', ogProduct.attr('og-subscription-id'));
		if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW || url.indexOf('custom') > -1) {
			url = util.appendParamToURL(url, 'customBall', true);
			window.location.href = util.appendParamToURL(url, 'source', 'subscription');
			return;
		}
		quickview.show({
			url: url,
			source: 'subscription'
		});
	});

	$('.profile-columns').on('click', '.manage-payment', function (e) {
		e.preventDefault();
		var unsentShipment = $(this).closest('.og-unsent-shipment');
        var subscriptionIds = unsentShipment.find('.og-product').map(function () {
            return $(this).attr('og-subscription-id');
        }).get();
		window.location.href = util.appendParamToURL(Urls.paymentsList, 'subscriptionIDs', subscriptionIds.join('_') || '');
	});
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
	toggleFullOrder();
	initAddressEvents();
	initPaymentEvents();
	initProfilePage();
	initSOMOrderHistory();
	initOGMySubscription();
    imageSliders.setupRecCarousel();
	$('.login-box-content form .form-row').each(function () {
		if ($(this).hasClass('label-inline')) {
			$(this).find('input').clone().insertBefore($(this).find('label'));
			$(this).find('.field-wrapper').remove();
			$(this).find('.form-caption').remove();
		}
	});
	$('.content-summary__collapsible-header').on('click', function (e) {
		$('.content-summary').toggleClass('expand');
	});
	$('body').on('focus', 'input[id*="creditcards_newcreditcard_number"]', function (event) {
        $(this).attr("maxlength", "25");
	});
	$('body').on('focus', '.phone', function () {
		var $form = $(this).parents('form');
		var countryCode = '';
		if ($form.data('store-locale')) {
			countryCode = $form.data('store-locale').slice(-2);
		}
		if (countryCode === '' || countryCode === 'US' || countryCode === 'CA') {
			$(this).mask('(999) 999-9999');
		}
	});
	$('body').on('keyup', 'input[id*="creditcards_newcreditcard_number"]', function (event) {
        var key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            return false;
        }
		var accountNumber = $(this).val();
		var result = "";
		if (/^[2,5][1-5]/.test(accountNumber)) {
            // "MasterCard"
			$("select[id*='newcreditcard_type']").val("MasterCard");
			$(this).val(function (index, value) {
				return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
			});
		} else if (/^4/.test(accountNumber)) {
            // "Visa"
			$("select[id*='newcreditcard_type']").val("Visa");
			$(this).val(function (index, value) {
				return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
			});
		} else if (/^3[47]/.test(accountNumber)) {
            //  "Amex"
			$("select[id*='newcreditcard_type']").val("Amex");
            if (accountNumber.match(/^3[47]\d{2}/)) {
                if (accountNumber.length == 4) {
                  accountNumber += ' ';
                  $(this).val(accountNumber);
                }
                if (accountNumber.length == 11) {
                    accountNumber += ' ';
                    $(this).val(accountNumber);
                }
                if (accountNumber.length > 17) {
                    val = $(this).val().substr(0, $(this).val().length - 1);
                    $(this).val(val);
                }
            } else {
                if (accountNumber.length > 16) {
                    val = $(this).val().substr(0, $(this).val().length - 1);
                    $(this).val(val);
                }
                if (accountNumber.length == 16) {
                    var splits = accountNumber.match(/\d{4}/g);
                    val = splits.join(' ');
                    $(this).val(val);
                }
            }
		} else if (/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(accountNumber)) {
            // "Discover"
			$("select[id*='newcreditcard_type']").val("Discover");
			$(this).val(function (index, value) {
				return value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
			});
		}
	});
	$('form.registered-form').submit(function (e) {
		var form = $(this);
		var customerDate = form.find('input.customer-birthday');
		var customerDateValue = customerDate.val();
		if (form.valid() && customerDateValue) {
			var splittedValues = customerDateValue.split("-");
			var newDateFormat = splittedValues[1] + "/" + splittedValues[2] + "/" + splittedValues[0];
			customerDate.attr('type', 'text');
			customerDate.val(newDateFormat);
		}
	});
}

var account = {
	init: function () {
		initializeEvents();
		giftcert.init();
		contactus.init();
		productTile.init();
		hellmannAVS.init();
		headingToggleButton.init();
		wishlist.init();
	}
};

module.exports = account;
