'use strict';
/*
 * formPrepare.init() accepts the following params:
 * 	formSelector: valid CSS selector for the form, ex: 'form[id$="billing"]',
 * 	continueSelector: valid CSS selector for the submit button, ex: '[name$="billing_save"]',
 * 	canDisable: if explicitly false, will not utilize the "disable" attribute (optional, default is true)
 */

var _ = require('lodash');

var $form, $continue, $requiredInputs, validator, canDisable, hasPreferredAddress, $requiredRadiosChecked, $boniversum;

var hasEmptyRequired = function () {
	// filter out only the visible fields
	var requiredValues = $requiredInputs.filter(':visible').map(function () {
		return $(this).val();
	});
	return _(requiredValues).includes('');
};

var boniversumCheck = function () {
    var $isBoniversum = $form.find('input[name$="_isBoniversum"]');
    var $isGermanSite = $('.de-locale').length > 0;
    if ($isGermanSite) {
        $boniversum = true;
	} else if ($isBoniversum.length > 0 && $isBoniversum.filter(':checked').val() !== 'true') {
		$boniversum = false;
	} else {
		$boniversum = true;
	}
};

var validateForm = function () {
	// only validate form when all required fields are filled to avoid
	// throwing errors on empty form
	if (!validator) {
		return;
	}
	var $requiredRadios = $form.find(':radio.required');
	if ($requiredRadios.length > 0) {
		$requiredRadiosChecked = $requiredRadios.is(':checked');
	} else {
		$requiredRadiosChecked = true;
	}
	boniversumCheck();
	if (!hasEmptyRequired() && validator.form() && $requiredRadiosChecked && $boniversum) {
		enableBtn($continue);
	} else {
		disableBtn($continue);
	}
};

var validateEl = function () {
	if ($(this).val() === '') {
		disableBtn($continue);
	} else {
		// enable continue button on last required field that is valid
		// only validate single field
		boniversumCheck();
		if (validator.element(this) && !hasEmptyRequired() && $boniversum && validator.form()) {
			enableBtn($continue);
		} else {
			disableBtn($continue);
		}
	}
};

var enableBtn = function ($btn) {
	$btn.removeClass('disabled');
	if (canDisable) {
		$btn.removeAttr('disabled');
	}
};

var disableBtn = function ($btn) {
	$btn.addClass('disabled');
	if (canDisable) {
		$btn.attr('disabled', 'disabled');
	}
};

var init = function (opts) {
	if (!opts.formSelector || !opts.continueSelector) {
		throw new Error('Missing form and continue action selectors.');
	}
	$form = $(opts.formSelector);
	$continue = $(opts.continueSelector);
	$requiredInputs = $('.required :input', $form).not(':button');
	validator = $form.validate();
	canDisable = opts.canDisable !== false;
    hasPreferredAddress = opts.hasPreferredAddress;
	validateForm();
    if (hasPreferredAddress) {
        enableBtn($continue);
	}
	// start listening
	$requiredInputs.on('change', validateEl);
	$requiredInputs.filter('input').on('blur', _.debounce(validateEl, 200));
	$requiredInputs.filter('input[name*="cvn"]').on('keyup', _.debounce(validateEl, 200));
	$form.find('input[name$="_isBoniversum"]').on('change', function () {
		validateForm();
    });

    $(document).ready(function () {
        $form.find('input[name$="_confirmshipping"]').on('change', validateEl);
    });
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
