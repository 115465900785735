/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */
'use strict';

var countries = require('./countries'),
    cq = require('./cq'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    searchsuggest = require('./searchsuggest'),
    searchsuggestbeta = require('./searchsuggest-beta'),
    headermenu = require('./headermenu'),
    headerslider = require('./headerslider'),
    headersearch = require('./headersearch'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    accountt = require('./pages/accountt'),
    product = require('./pages/product/index'),
    club = require('./pages/product/custom/club'),
    compare = require('./pages/compare'),
    productCompare = require('./pages/product/compare'),
    customdialog = require('./customdialog'),
    validator = require('./validator'),
    registration = require('./pages/account/registration'),
    globalNotificationBanner = require('./globalNotificationBanner'),
    contactus = require('./contact-us');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}
require('./jquery-ext')();
require('./cookieprivacy')();

//initialize YouTube API
require('./youtube')();

require('../../int_ordergroove/cartridge/client/default/js/optins')();

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
    // set focus on page load
    if (!document.activeElement || document.activeElement.nodeName.toLowerCase() == 'body') {
        var searchPage = document.querySelector('.pt_plp-page');
        try {
            if (searchPage && window.history.state !== null && window.history.state.scrollPosition !== null) {
                window.scrollTo({top: window.history.state.scrollPosition});
            }
        }
        catch (e) {
           console.log('scrollPosition undefined');
        }
    }
    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('click', '.ui-widget-overlay', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        }).on('touchstart', function () {
        $(this).addClass('touch');
    });

    if (util.isMobile()) {
        $('body').addClass('touch');
    }

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
        $(this).toggleClass('open');
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle:not(.refinement h3)').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0) {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({
                color: '#999999'
            }, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({
                    color: '#333333'
                }, 500, 'linear');
        });
    }

    $('.email-alert-signup-popup').on('submit', function (e) {
        e.preventDefault();
        var emailForm = $(this);

        if (emailForm.valid() === false) {
            return false;
        }
        var submitButton = emailForm.find('button[type="submit"]');
        var emailField = emailForm.find('.input-text').first();
        var email = emailField.val();
        var successMessage = $('#footer-signup-ty');

        $.get('/ajax/site-intercept/intercept-manager.aspx?e=' + email, function (data) {
            data = JSON.parse(data);
            if ('status_code' in data) {
                if (data.status_code === 200) {
                    emailField.hide();
                    submitButton.hide();
                    successMessage.show();
                } else {
                    $('#footer-signup-error').html(data.message).show();
                }
            } else {
                $('#footer-signup-error').html('internal error').show();
            }
        });
    });

    $('#term, .privacy-policy, .save-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600,
                open: function () {
                    $('#dialog-container').animate({
                        scrollTop: 0
                    }, '100');
                }
            }
        });
    });
    $('#term-subscriptions').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600,
                open: function () {
                    setTimeout(function () {
                        $(".ui-dialog-content").scrollTop("0");
                        var offset = $('#subscriptions').position().top;
                        if (offset > 0) {
                            $('#dialog-container').animate({
                                scrollTop: offset
                            }, 100);
                        }
                    }, 100);
                }
            }
        });
    });
    // print handler
    $('.print-page').on('click', function () {
        window.print();
        return false;
    });
    // Change Countries
    $(document).on('click', '.countries-container > a', function (e) {
        e.preventDefault();
        $(this).next().toggle();
    });
    $(document).on('click', '.close-countries', function (e) {
        e.preventDefault();
        $('.country-container').hide();
    });
    if ($('.intersitial-message-wrapper').length) {
        dialog.open({
            html: $('.intersitial-message-wrapper').html(),
            options: {
                dialogClass: 'no-close intersitial-message-modal',
                width: 900,
				close: function () {
					$(document).trigger('close.interstitial');
				}
            }
        });
        $('.show-countries').on('click', function () {
            dialog.close();
            if ($('.countries-container > a').length > 0) {
                $('.countries-container > a').click();
                $('html, body').animate({
                    scrollTop: $('.countries-container > a').offset().top
                }, 1000);
            } else {
                $('.countries-container .icon-expand').click();
                $('html, body').animate({
                    scrollTop: $('.countries-container .icon-expand').offset().top
                }, 1000);
            }
        });
    }
    //Show header bottom menu
    var bannerCookieId = $('#header-bottom-banner').data('cookie-id');
    if (document.cookie.indexOf(bannerCookieId) < 0 && $('#header-bottom-banner .header-bottom-banner-content').children().length > 0) {
        $('#header-bottom-banner').removeClass('visually-hidden');
    }
    $('#header-bottom-banner .banner-close-btn').on('click', function () {
        $('#header-bottom-banner').addClass('visually-hidden');
        document.cookie = bannerCookieId + '=1; path=/';
    });
    // Footer Return
    $('.footer-item h3').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.footer-item').toggleClass('active');
    });
    $('.footer-return span').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });

    $('.menu-utility-user .user-info').on('mouseenter', function () {
        $(this).addClass('active');
    });
    $('.menu-utility-user .user-info').on('mouseleave', function () {
        $(this).removeClass('active');
    });
    $('#help >a').on('click', function (e) {
        e.preventDefault();
    });
    $('.menu-utility-user #help').on('mouseenter', function () {
        $(this).addClass('active');
    });
    $('.menu-utility-user #help').on('mouseleave', function () {
        $(this).removeClass('active');
    });
    //Titleist menu utility
    $('.menu-utility-user.titliest > #search-cell > a').on('click', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('is-open');
    });
    //Titleist forms in assets

    $('#searchForPlayer button[type="submit"]').click(function (e) {
        $('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
        var input = $(this).siblings('input');
        if (!input.val() && !input.hasClass('zip-error')) {
            e.preventDefault();
            input.addClass('zip-error').after('<span class="zip-error">Enter a player\'s name</span>');
        } else if (!input.val() && input.hasClass('zip-error')) {
            e.preventDefault();
        } else if (input.val()) {
            $('#searchForPlayer').submit();
        }
    });
    $('#zipFindGolfShop button[type="submit"]').click(function (e) {
        $('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
        var input = $(this).siblings('input');
        if (!input.val() && !input.hasClass('zip-error')) {
            e.preventDefault();
            input.addClass('zip-error').after('<span class="zip-error">Please enter an address</span>');
        } else if (!input.val() && input.hasClass('zip-error')) {
            e.preventDefault();
        } else if (input.val()) {
            $('#zipFindGolfShop').submit();
        }
    });
    $('#searchHeaderSubmit button[type="submit"]').click(function (e) {
        $('#searchForPlayer, #zipFindGolfShop, #searchHeaderSubmit').off();
        var input = $(this).siblings('input');
        if (!input.val() && !input.hasClass('zip-error')) {
            e.preventDefault();
            input.addClass('zip-error').after('<span class="zip-error">Please enter a search term</span>');
        } else if (!input.val() && input.hasClass('zip-error')) {
            e.preventDefault();
        } else if (input.val()) {
            $('#searchHeaderSubmit').submit();
        }
    });

    $('.footer-item .footer-dd-container .has-dropdown').on('click', function () {
        if (util.getViewport() < 768) {
            $(this).siblings('ul').toggle();
            $(this).toggleClass('active');
        }
    });

    //Titleist footer js
    $('.menu-languages .btn-toggle').on('click', function () {
        $(this).parents('.has-children').toggleClass('is-open');
    });

    $('.collapsible-menu-heading').on('click', function () {
        $(this).siblings('.collapsible-menu').toggleClass('is-active');
        if ($(this).find('.collapsible-icon').html() === '+') {
            $(this).find('.collapsible-icon').html('-');
        } else {
            $(this).find('.collapsible-icon').html('+');
        }
    });

    $('.menu-global').on('click', 'button', function () {
        if ($(this).attr('aria-expanded') == 'true') {
            $(this).attr('aria-expanded', 'false');
            $(this).parent().removeClass('is-open');
            $(this).siblings('.menu-content').hide();
        } else {
            $(this).attr('aria-expanded', 'true');
            $(this).parent().addClass('is-open');
            $(this).siblings('.menu-content').show();
        }
    });

    $('.titleist-footer .icon-subscribe-to-newsletter').on('click', function () {
        $("form.footer-enews-sign-up").trigger('submit');
    });

    $("form.footer-enews-sign-up").submit(function (event) {
        event.preventDefault();
        submitEnewsSignUp(event, $(this));
    });

    //Team Titleist event handler
    $('div.footer-enews-sign-up .icon-subscribe-to-newsletter').on('click', function (event) {
        event.preventDefault();
        submitEnewsSignUp(event, $('div.footer-enews-sign-up'));
    });

    $('div.footer-enews-sign-up #footer-email').on('keyup', function (event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            submitEnewsSignUp(event, $('div.footer-enews-sign-up'));
        }
    });

    function submitEnewsSignUp(event, $container) {
        var postUrl = $container.attr('action');
        var emailAddress = $container.find("input[id='footer-email']").val();
        var postData = {
            emailaddr: emailAddress
        };
        //console.log('postUrl = ' + postUrl + 'postData = ' + postData);

        if ($container.data('submitted') === true) {
            // Previously submitted - don't submit again
            event.preventDefault();
        } else {
            // Mark it so that the next submit can be ignored
            var resultWrapperClass = "";
            $container.find("div.signup-error, div.signup-success").remove();
            var resultWrapper = $container.find("div");
            resultWrapper.find("#footer-email,.icon-subscribe-to-newsletter").hide();

            var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
            if (!pattern.test(emailAddress)) {
                resultWrapperClass = "signup-error";
                resultWrapper.find("#footer-email,.icon-subscribe-to-newsletter").show();
                $("<div class=\"" + resultWrapperClass + "\"><p>" + resultWrapper.find("span.invalid-email-error").html() + "</p></div>").appendTo(resultWrapper);
                return false;
            }

            $.ajax({
                type: "POST",
                url: postUrl,
                data: postData,
                dataType: 'json',
                context: this
            }).done(function (result) {
                var resultWrapperClass = "signup-error";

                if (result.status_code != "200") {
                    resultWrapperClass = "signup-error";
                    resultWrapper.find("#footer-email,.icon-subscribe-to-newsletter").show();
                    if (result.status == "server error") {
                        resultWrapperClass = resultWrapperClass + " server-error";
                    }
                    $("<div class=\"" + resultWrapperClass + "\"><p>" + result.message + "</p></div>").appendTo(resultWrapper);
                } else {
                    resultWrapperClass = "signup-success";
                    $("<div class=\"" + resultWrapperClass + "\"><p>" + result.message + "</p></div>").appendTo(resultWrapper);
                    $container.data('submitted', true);

                    /***pushGAEvent('Email Signup Form', 'submit', 'footer', '');***/
                }
            });
        }
        return false;
    }



    //Titleist header js
    $(document).ready(function () {
        var $body = $('body');
        var $nav = $('.titleist-nav');
        var button;

        function navClose() {
            if ($body.hasClass('nav-is-open')) {
                $body.removeClass('nav-is-open');
                $('html').removeClass('nav-fix');
                $body.find('.modal-overlay').remove();
            }
        }
        $nav.find('.nav-item > a:not(.no-sub)').on('click', function (e) {
            e.preventDefault();
            var settings = {
                speed: 400,
                classname: 'is-active'
            };
            var $navLink = $(this);
            var navItem = $navLink.parent();
            var subMenu = navItem.find('.sub-menu');
            if ($body.width() >= 800) {
                if (navItem.hasClass(settings.classname)) {
                    subMenu.slideUp(100, function () {
                        navItem.removeClass(settings.classname);
                        subMenu.removeAttr('style');
                        navClose();
                    });
                } else {
                    if ($nav.find('.nav-item.is-active').length) {
                        $nav.find('.nav-item.is-active').find('.sub-menu').slideUp(100, function () {
                            navItem.siblings().removeClass(settings.classname).find('.sub-menu').removeAttr('style');
                            navItem.addClass(settings.classname);
                            subMenu.hide().slideDown(200, function () {
                                subMenu.removeAttr('style');
                                navClose();
                            });
                        });
                    } else {
                        navItem.addClass(settings.classname);
                        subMenu.hide().slideDown(200, function () {
                            subMenu.removeAttr('style');
                        });
                    }
                }
            } else {
                navItem.siblings().removeClass(settings.classname);
                if (navItem.hasClass(settings.classname)) {
                    navItem.removeClass(settings.classname);
                    navClose();
                } else {
                    navItem.addClass(settings.classname);
                    if ($('.modal-overlay').length === 0) {
                        $body.addClass('nav-is-open').prepend('<div class="modal-overlay" />');
                        $('html').addClass('nav-fix');
                        $('.modal-overlay').hide().fadeIn(300);
                    }
                }
            }
        });
        $body.on('click', '.modal-overlay', function () {
            button = $nav.find('.nav-item.is-active').find('> a');
            button.closest('.nav-item').removeClass('is-active');
            navClose();
        });
        $(document).keyup(function (event) {
            if (event.keyCode === 27) {
                var isOpen = $('.titleist-menu').find('.is-open');
                $nav.find('.nav-item.is-active').removeClass('is-active');
                navClose();
                if ($body.hasClass('menu-is-open')) {
                    isOpen.find('.btn-toggle').trigger('click').focus();
                }
                isOpen.find('.is-open').find('.menu-content').attr('aria-hidden', 'true');
            }
        });

        $(document).on('click', '.promotion-callout-tooltip', function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                options: {
                    dialogClass: 'promo-details-dialog',
                    open: function () {
                        $(this).parent().focus();
                    }
                }
            });
        });
    });

    // Create listener to open the search box.
    $('.titleist-search-box-cta').on('click', function () {
        if ($('nav.primary').hasClass('is-animated')) {
            $('html, body').animate({scrollTop: '0px'}, 200);
        }
		$(this).toggleClass('active');
		$('.titleist-search-box').toggleClass('active');
		$('#mobile-search').toggleClass('is-open');
    });

    window.picturefill({
        reevaluate: true
    });

	var appHeight = function () {
		var doc = document.documentElement;
		doc.style.setProperty('--app-height', window.innerHeight + 'px');
	};
	window.addEventListener('resize', appHeight);
	appHeight();
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

/**
 * @function initFreezeBackground
 * @description Set up listeners to freeze the page background in place, or unfreeze it. init freeze on modal show, unfreeze on modal disappear.
 *    This aims to fix a known iOS quirk which allows scrolling of the background when it should not be accessible by the user.
 * @example $(document).trigger('freezeBackground');
 */
function initFreezeBackground() {
    $(document).on('freezeBackground', function () {
		$('html').addClass('freeze-scroll');
    }).on('unfreezeBackground', function () {
		$('html').removeClass('freeze-scroll');
    });
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    catlanding: require('./pages/catlanding'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    configurator: require('./pages/configurator'),
    customizer: require('./pages/customizer'),
    product: require('./pages/product'),
    club: require('./pages/product/custom/club'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    protection: require('./pages/protection/protection'),
    surefitdetails: require('./pages/configurator'),
    orderconfirmation: require('./pages/orderconfirmation')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        util.initializeParamsInURL();

        productCompare();
        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        minicart.updateMiniCart();
        validator.init();
        rating.init();
        headermenu.init();
        headersearch.init();
        headerslider.init();
        cq.init();
        customdialog.init();
        accountt.init();
		initFreezeBackground();
        registration.init();
        globalNotificationBanner.init();
        contactus.init();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
        // Titleist headroom
        var offset = $('#banner').outerHeight();
        if (window.headroom !== undefined) {
            if (window.headerOffset !== undefined && window.headerOffset === offset) {
                return;
            }

            window.headroom.destroy();
            $('#banner').removeData('headroom');

            window.headerOffset = offset;
            window.headroom = new Headroom($('#banner').get(0), {
                'offset': offset,
                'tolerance': 5,
                'classes': {
                    'initial': 'banner--fixed',
                    'unpinned': 'banner--down'
                },
                onUnpin: function () {
                    // Close open navigation when scrolling down
                    $('.titleist-nav').find('.nav-item.is-active').removeClass('is-active');
                }
            });

            window.headroom.init();
            $('html').css('margin-top', offset);
        }

        // Update locales in country selector
        if (SitePreferences.PROTECTION_HIDE_BLOCK_LOCALE) {
            var protectionLocalesToBlock = Protection.protectionLocalesToBlock;
            if (protectionLocalesToBlock) {
                for (var i = 0; i < protectionLocalesToBlock.length; i++) {
                    // Hide locales in country selector
                    var protectionLocale = protectionLocalesToBlock[i];
                    var locale = '/' + protectionLocale + '/';
                    $('#countryMenu .menu-content a[href*="' + locale + '"]').parent().remove();
                }
            }
        }
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();

    // Initialize Amazon Pay
    if (SitePreferences.AMAZON_PAY_ENABLED) {
        var amazonPay = require('./amazonPay');
    }
});
